<script setup lang="ts">
import type { PropType } from 'vue';
import LoadingSpinner from '../icons/LoadingSpinner.vue';

type Style = 'gray' | 'full-width' | 'auto-width' | 'custom-width' | 'border';

const props = defineProps({
  types: {
    type: Array as PropType<Style[]>,
    default: [] as Style[]
  },
  customStyles: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean,
    default: false
  }
});

const styles = {
  'gray': 'bg-gray-700',
  'full-width': 'w-full bg-gray-700',
  'auto-width': 'w-auto',
  'custom-width': 'border border-gray-600',
  'border': 'border border-gray-600'
};

const getStyles = () => Array.isArray(props.types) && props.types?.length > 0 ? props.types?.map(type => {
  const style = styles[type as keyof typeof styles];
  if (style == null) return 'border border-gray-600 w-20 md:w-28';

  return style;
}).join(' ') : 'border border-gray-600 w-20 md:w-28';
</script>

<template>
  <button
    class="inline-block px-4 py-1.5 transition-all rounded-md hover:bg-gray-600"
    :class="[customStyles, getStyles(), disabled && 'pointer-events-none border-gray-600 text-gray-500']"
  >
    <LoadingSpinner
      v-if="props.isLoading"
      class="w-5 h-5"
    />
    <slot v-else />
  </button>
</template>
